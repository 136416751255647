
import {defineComponent, ref, computed, inject, watch, onMounted, Ref} from 'vue';
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {TApi} from "@/api/types";
import { AxiosResponse } from "axios";
import {TTypeOperation} from "@/api/modules/users/types";
import {useToast} from "primevue/usetoast";


export default defineComponent({
  name: 'ResetPassword',
  setup: function () {
    onMounted(() => {
      1
    });

    const step: Ref<number> = ref(1);

    const email: Ref<string | null> = ref(null);
    const secretKey: Ref<string | null> = ref(null);
    const password1: Ref<string | null> = ref(null);
    const password2: Ref<string | null> = ref(null);

    const pass1 = ref();

    const res_pass_id = ref(undefined);
    const is_verefy_key = ref(false);
    const api: TApi = inject('api') as TApi;
    const toast = useToast();
    const showResetPassword: any = inject('showResetPassword');

    const showPass2 = computed(() => !pass1?.value?.unmasked);
    /* region Валидация */
    const submitted = ref(false);

    const validateEmail = (email: string) => {
      return email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };

    const checkEmail: any = ref({
      count: 0,
      isLoading: false,
      isExists: false,
      inputTimeout: undefined,
    });

    watch(() => email.value, () => {
      if (checkEmail.value.inputTimeout) {
        clearTimeout(checkEmail.value.inputTimeout);
      }
      if (email.value !== null) {
        if (email.value.length === 0) {
          checkEmail.value.isLoading = false;
          checkEmail.value.count = 0;
        }
        if (email.value.length > 0) {
          checkEmail.value.isLoading = true;
          checkEmail.value.count += 1;
          checkEmail.value.inputTimeout = setTimeout(() => {
            api.apiUsers.checkEmail(email.value ?? '').then((res: AxiosResponse) => {
              checkEmail.value.isExists = !res.data.check;
            }).finally(() => {
              checkEmail.value.isLoading = false;
            });
          }, 500);
        }
      }
    });

    const source = computed(() => {
      return {
        email: email.value,
        secretKey: secretKey.value,
        password1: password1.value,
        password2: password2.value
      }
    });

    const rules = computed(() => {
      let obj = {}
      if (step.value === 1) {
        obj = { email: {required, validateEmail} }
      } else if (step.value === 2) {
        obj = { secretKey: {required} }
      } else if (step.value === 3) {
        if (showPass2.value) {
          obj = { password1: {required}, password2: {required} }
        } else {
          obj = { password1: {required}}
        }
      }
      return obj
    });

    const v$ = useVuelidate(rules, source);

    const isFormValid = computed(() => !v$.value.$invalid);
    /* endregion */

    const cancelAddUser = () => {
      showResetPassword.value = false;
    };

    const resetPassword = async (val: TTypeOperation) => {
      submitted.value = true;
      if (!isFormValid.value) {
        return
      }
      switch (val) {
        case 'init':
          if (email.value) {
            api.apiUsers.resetPassword('init', {email: email.value})
                .then((res) => {
                  step.value = 2;
                  res_pass_id.value = res.data.res_pass_id;
                  submitted.value = false;
                })
                .catch((e) => {
                  console.log(e);
                  step.value = 1;
                  toast.add({
                    severity: 'error',
                    summary: 'Ошибка',
                    detail: `${e?.response?.data?.detail}`,
                    life: 3000
                  });
                })
          }
          break;
        case 'secret_key':
          if (email.value && secretKey.value) {
            api.apiUsers.resetPassword('secret_key', {email: email.value, secret_key: secretKey.value, res_pass_id: res_pass_id.value})
                .then((res) => {
                  step.value = 3;
                  is_verefy_key.value = res?.data?.check_secret_key;
                  submitted.value = false;
                })
                .catch((e) => {
                  console.log(e);
                  step.value = 2;
                  toast.add({
                    severity: 'error',
                    summary: 'Ошибка',
                    detail: `${e?.response?.data?.detail}`,
                    life: 3000
                  });
                })
          }
          break;
        case 'reset':
          if (email.value && secretKey.value && password1.value) {
            api.apiUsers.resetPassword('reset', {email: email.value, secret_key: secretKey.value, password: password1.value})
                .then(() => {
                  toast.add({
                    severity: 'success',
                    summary: 'Пароль успешно изменен',
                    life: 3000
                  });
                  submitted.value = false;
                  showResetPassword.value = false;
                })
                .catch((e) => {
                  console.log(e);
                  toast.add({
                    severity: 'error',
                    summary: 'Ошибка',
                    detail: `${e?.response?.data?.detail}`,
                    life: 3000
                  });
                  showResetPassword.value = false;
                })
          }
          break;
      }
    };

    const showButton = computed(() => {
      if (step.value === 1) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return !(email.value === null || email.value === '' || (checkEmail.value.count > 0 && !checkEmail.value.isLoading && !checkEmail.value.isExists) || (v$.value?.email?.validateEmail.$invalid && checkEmail.value.count > 0))
      } else if (step.value === 2) {
        return true
      } else if (step.value === 3) {
        if (showPass2.value) {
          return password1.value === password2.value && password1.value !== null && password1.value !== '' && password2.value !== null && password2.value !== ''
        } else {
          return password1.value !== null && password1.value !== ''
        }
      }
      return true
    });

    return {
      email,
      secretKey,
      v$,
      checkEmail,
      submitted,
      isFormValid,
      step,
      password1,
      password2,
      showPass2,
      pass1,
      showButton,
      cancelAddUser,
      resetPassword
    }
  }
});

