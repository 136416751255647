
import {computed, defineComponent, inject, onMounted, provide, ref,} from 'vue';
import { useRouter } from "vue-router";
import store from '@/store'
import {useToast} from "primevue/usetoast";
import checkErrorCode from "@/source/functions/responseHandler";
import AddUser from "@/components/users/AddUser.vue";
import ResetPassword from "@/components/users/ResetPassword.vue";

export default defineComponent({
  name: 'Login',
  components: {
    ResetPassword,
    AddUser
  },
  setup() {
    onMounted(() => {
      user.value = store.getters['login/getUser'];
    });

    const login = ref('');
    const password = ref('');
    const router = useRouter();
    const toast = useToast();
    const showAddUser = ref(false);
    provide('showAddUser', showAddUser);

    const showResetPassword = ref(false);
    provide('showResetPassword', showResetPassword);
    const buildData: any = inject('buildData');

    const signIn = () => {
      const payload = {username: login.value, password: password.value}
      store.commit('root/setLoadingStatus', true);
      store.dispatch('login/signIn', payload).then(() => {
        user.value = store.getters['login/getUser'];
        store.dispatch('users/loadPhotoUser');
        buildData();
      }).catch((err) => {
        toast.add(checkErrorCode(err.response.status));
      }).finally(() => {
        user.value = store.getters['login/getUser'];
        store.commit('root/setLoadingStatus', false);
        if (user.value.is_admin) {
          router.push({name: 'Admin'});
        } else {
          router.push({name: 'Home'});
        }
      });
    }

    const user = ref({}) as any;

    return {
      login,
      password,
      showAddUser,
      showResetPassword,
      signIn,

    }
  }
});
