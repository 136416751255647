import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a9e2c9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex align-items-start justify-content-start flex-column z-w-100" }
const _hoisted_2 = {
  key: 0,
  class: "flex align-items-start justify-content-start gap-3 flex-column z-w-100"
}
const _hoisted_3 = { class: "flex t justify-content-between align-items-start flex-column mb-3 z-w-100" }
const _hoisted_4 = {
  class: "p-input-icon-right",
  style: {'width': '100%'}
}
const _hoisted_5 = {
  key: 0,
  class: "pi pi-spin pi-spinner"
}
const _hoisted_6 = {
  key: 1,
  class: "pi pi-check is-succes"
}
const _hoisted_7 = {
  key: 2,
  class: "pi pi-times is-error"
}
const _hoisted_8 = {
  key: 0,
  class: "p-error"
}
const _hoisted_9 = {
  key: 1,
  class: "p-error"
}
const _hoisted_10 = { class: "flex align-items-start justify-content-end gap-3 z-w-100" }
const _hoisted_11 = {
  key: 1,
  class: "flex align-items-start justify-content-start gap-3 flex-column z-w-100"
}
const _hoisted_12 = { class: "flex t justify-content-between align-items-start flex-column mb-3 z-w-100" }
const _hoisted_13 = {
  key: 0,
  class: "p-error"
}
const _hoisted_14 = { class: "flex align-items-start justify-content-end gap-3 z-w-100" }
const _hoisted_15 = {
  key: 2,
  class: "flex align-items-start justify-content-start gap-3 flex-column z-w-100"
}
const _hoisted_16 = { class: "flex gap-2 justify-content-between align-items-start flex-column mb-3 z-w-100" }
const _hoisted_17 = {
  key: 2,
  class: "p-error"
}
const _hoisted_18 = { class: "flex align-items-start justify-content-end gap-3 z-w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmPopup = _resolveComponent("ConfirmPopup")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_InputMask = _resolveComponent("InputMask")!
  const _component_Password = _resolveComponent("Password")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ConfirmPopup),
    (_ctx.step === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", {
              class: _normalizeClass(["mb-2", {'p-error':_ctx.v$.email?.$invalid && _ctx.submitted}])
            }, "Введите ваш email", 2),
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_InputText, {
                placeholder: "Email",
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                class: _normalizeClass({'p-invalid':_ctx.v$.email?.$invalid && _ctx.submitted}),
                style: {'width': '100%'}
              }, null, 8, ["modelValue", "class"]),
              (_ctx.checkEmail.count > 0 && _ctx.checkEmail.isLoading)
                ? (_openBlock(), _createElementBlock("i", _hoisted_5))
                : _createCommentVNode("", true),
              (_ctx.checkEmail.count > 0 && !_ctx.checkEmail.isLoading && _ctx.checkEmail.isExists)
                ? (_openBlock(), _createElementBlock("i", _hoisted_6))
                : _createCommentVNode("", true),
              (_ctx.checkEmail.count > 0 && !_ctx.checkEmail.isLoading && !_ctx.checkEmail.isExists)
                ? (_openBlock(), _createElementBlock("i", _hoisted_7))
                : _createCommentVNode("", true)
            ]),
            (_ctx.v$.email?.validateEmail.$invalid && _ctx.checkEmail.count > 0)
              ? (_openBlock(), _createElementBlock("small", _hoisted_8, "Не корректный email адрес"))
              : _createCommentVNode("", true),
            (_ctx.checkEmail.count > 0 && !_ctx.checkEmail.isLoading && !_ctx.checkEmail.isExists)
              ? (_openBlock(), _createElementBlock("small", _hoisted_9, "Адрес не найден"))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_Button, {
              label: "Отменить",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancelAddUser())),
              icon: "pi pi-times",
              class: "p-button-outlined"
            }),
            _createVNode(_component_Button, {
              label: 'Далее',
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.resetPassword('init'))),
              disabled: !_ctx.showButton,
              icon: "pi pi-check",
              autofocus: ""
            }, null, 8, ["disabled"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.step === 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("label", {
              class: _normalizeClass(["mb-2", {'p-error':_ctx.v$.secretKey?.$invalid && _ctx.submitted}])
            }, "Введите код отправленный на указанный email.", 2),
            _createVNode(_component_InputMask, {
              placeholder: "Код подтверждения",
              modelValue: _ctx.secretKey,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.secretKey) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$.secretKey?.$invalid && _ctx.submitted}),
              style: {'width': '100%'},
              mask: "999999"
            }, null, 8, ["modelValue", "class"]),
            (_ctx.checkEmail.count > 0 && !_ctx.checkEmail.isLoading && !_ctx.checkEmail.isExists)
              ? (_openBlock(), _createElementBlock("small", _hoisted_13, "Адрес не найден"))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_Button, {
              label: "Отменить",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.cancelAddUser())),
              icon: "pi pi-times",
              class: "p-button-outlined"
            }),
            _createVNode(_component_Button, {
              label: 'Далее',
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.resetPassword('secret_key'))),
              disabled: !_ctx.showButton,
              icon: "pi pi-check",
              autofocus: ""
            }, null, 8, ["disabled"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.step === 3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("label", {
              class: _normalizeClass(["mb-2", {'p-error':_ctx.v$.secretKey?.$invalid && _ctx.submitted}])
            }, "Введите пароль", 2),
            _createVNode(_component_Password, {
              ref: "pass1",
              modelValue: _ctx.password1,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.password1) = $event)),
              feedback: false,
              inputStyle: {'width': '100%'},
              style: {'width': '100%'},
              toggleMask: ""
            }, null, 8, ["modelValue"]),
            (_ctx.showPass2)
              ? (_openBlock(), _createElementBlock("label", {
                  key: 0,
                  class: _normalizeClass(["mb-2", {'p-error':_ctx.v$.secretKey?.$invalid && _ctx.submitted}])
                }, "Повторите пароль", 2))
              : _createCommentVNode("", true),
            (_ctx.showPass2)
              ? (_openBlock(), _createBlock(_component_Password, {
                  key: 1,
                  modelValue: _ctx.password2,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.password2) = $event)),
                  feedback: false,
                  inputStyle: {'width': '100%'},
                  style: {'width': '100%'},
                  toggleMask: ""
                }, null, 8, ["modelValue"]))
              : _createCommentVNode("", true),
            (_ctx.checkEmail.count > 0 && !_ctx.checkEmail.isLoading && !_ctx.checkEmail.isExists)
              ? (_openBlock(), _createElementBlock("small", _hoisted_17, "Адрес не найден"))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_Button, {
              label: "Отменить",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.cancelAddUser())),
              icon: "pi pi-times",
              class: "p-button-outlined"
            }),
            _createVNode(_component_Button, {
              label: 'Далее',
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.resetPassword('reset'))),
              disabled: !_ctx.showButton,
              icon: "pi pi-check",
              autofocus: ""
            }, null, 8, ["disabled"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}